"use client";

import Link from "next/link";
import { HomeIcon, RefreshCw } from "lucide-react";

import { LighthouseLogoFull } from "@/components/ui/lighthouse-logo";
import { Button } from "@/components/ui/button";

export default function Error({
  reset,
  error,
}: {
  reset: () => void;
  error: Error & { digest?: string };
}) {
  return (
    <div>
      <div className="flex min-h-screen">
        <div className="m-auto space-y-20">
          <Link className="text-ring" href="/">
            <LighthouseLogoFull fill="fill-white" />
          </Link>
          <div className="space-y-2">
            <h1 className="text-center text-8xl font-black tracking-tight text-red-600">
              Server Error{" "}
              {error?.digest && (
                <span className="text-sm">( {error.digest} )</span>
              )}
            </h1>
            <h2 className="text-center text-xl font-black tracking-tight text-red-600">
              Something went wrong on our end. Please try again later.
            </h2>
            <div className="flex items-center justify-center gap-4">
              <Link
                className="text-xl text-ring hover:underline"
                href="/"
                prefetch={false}
              >
                <span className="flex items-center gap-1">
                  <HomeIcon className="h-5 w-5" />
                  Go Home
                </span>
              </Link>
              <Button
                className="text-xl text-ring"
                variant="link"
                onClick={() => reset()}
              >
                <span className="flex items-center gap-1">
                  <RefreshCw className="h-5 w-5" />
                  Try again
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
